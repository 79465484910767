import React, { useState, useEffect } from 'react';
import { Accordion, Button, Form, FormControl } from 'react-bootstrap';
import { InputGroup, Nav, Tab } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import styles from './SearchableAccordion.module.scss';
import { useMediaQuery } from 'react-responsive';
import AHRQSimpleSelect from '../Common/Select/AHRQSimpleSelect';
import { PageRow } from '../../ui';

const SearchableAccordion = (props) => {
  const { defaultTag, expandFirstItem } = props;
  const section = props.section;
  const onResetSearch = props.onResetSearch;
  const onApplyDataFilter = props.onApplyDataFilter;
  const setSearchBackground = props.setSearchBackground;
  const categoriesLabel = props.categoriesLabel ?? 'Categories';

  const phoneView = useMediaQuery({ query: `(max-width: 1200px)` }) ? true : false;
  const [searchTermParam, setSearchTermParam] = useState({ text: '' });
  const [scrollToPoistion, setScrollToPoistion] = useState(null);
  const [filterTimer, setFilterTimer] = useState(null);
  const [dataParam, setDataParam] = useState({ data: props.data });
  const [defaultModule, setDefaultModule] = useState(null); // default left side
  const [defaultModuleFromUrl, setDefaultModuleFromUrl] = useState(null); // default left side
  const [defaultModuleTab, setDefaultModuleTab] = useState(null); // default right side
  const [resetLeftTextKey, setResetLeftTextKey] = useState('');
  const [leftDictionary, setLeftDictionary] = useState(null); // data to left side
  const [toggleState, setToggleState] = useState(null); // action for toggle
  const [toggleStateExpandAllClickTracker, setToggleStateExpandAllClickTracker] = useState(null); // action for toggle
  const [copiedToClipboardTag, setCopiedToClipboardTag] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [resultCount, setResultCount] = useState(0);

  useEffect(() => {
    if (props.filterChanged) {
      onSearchChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterChanged]);

  useEffect(() => {
    let localDefaultModuleTab = defaultTag ?? 'empty';
    let localDefaultModule = 'empty';
    if (window.location.search) {
      var frags = window.location.search.split('&');
      if (frags && frags.length === 1) {
        var param = frags[0].split('=');
        if (param && param.length === 2 && param[0] === '?tag') {
          localDefaultModuleTab = param[1];
          setScrollToPoistion(`pane-acc-${localDefaultModuleTab}`);
        } else if (param && param.length === 2 && param[0] === '?category') {
          localDefaultModule = param[1];
        }
      }
    }

    const decodedLocalDefaultModuleTab = decodeURI(localDefaultModuleTab ?? 'empty');
    const decodedLocalDefaultModule = decodeURI(localDefaultModule ?? 'empty');
    setDefaultModuleTab(decodedLocalDefaultModuleTab);
    setDefaultModuleFromUrl(decodedLocalDefaultModule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataParam({ data: props.data });

    const tempToggleState = {};
    const tempToggleStateExpandAllClickTracker = {};
    for (const moduleKey of Object.keys(dataParam.data[section])) {
      for (const key of Object.keys(
        dataParam.data[section][moduleKey].groupInfo ?? { [moduleKey]: '' }
      )) {
        tempToggleState[key] = false;
        tempToggleStateExpandAllClickTracker[key] = false;
      }
    }
    setToggleState(tempToggleState);
    setToggleStateExpandAllClickTracker(tempToggleStateExpandAllClickTracker);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    let count = 0;
    for (const moduleKey of Object.keys(dataParam.data[section])) {
      count += dataParam.data[section][moduleKey].children.length;
    }
    setResultCount(count);
    setActiveCategory(Object.keys(dataParam.data[section])[0]);

    if (dataParam.data) {
      const dictionary = {};
      for (const key of Object.keys(dataParam.data[section])) {
        const item = dataParam.data[section][key];
        const childrenCount =
          dataParam.data[section][key].childrenCount ??
          dataParam.data[section][key].children.length;
        if (item.category) {
          if (dictionary[item.category] === undefined) {
            dictionary[item.category] = {
              children: {},
              topic: dataParam.data[`${item.category}-topic`],
              childrenCount: 0,
              groupInfo: item.groupInfo,
            };
          }
          dictionary[item.category].children[key] = { topic: item.topic, childrenCount };
          dictionary[item.category].childrenCount += childrenCount;
        } else {
          dictionary[key] = {
            topic: item.topic,
            childrenCount: childrenCount,
            active: false,
            groupInfo: item.groupInfo,
          };
        }
      }
      setLeftDictionary(dictionary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataParam]);

  useEffect(() => {
    if (defaultModuleTab) {
      if (defaultModuleTab === 'empty') {
        if (!defaultModuleFromUrl || defaultModuleFromUrl === 'empty') {
          var categoryToActivate = dataParam.data[`default-${section}`];
          setDefaultModule(categoryToActivate);
          setActiveCategory(categoryToActivate);
          setResetLeftTextKey(new Date().toTimeString().replace(/[^\d]/g, ''));
        } else {
          setDefaultModule(defaultModuleFromUrl);
          setActiveCategory(defaultModuleFromUrl);
          setResetLeftTextKey(new Date().toTimeString().replace(/[^\d]/g, ''));
          setDefaultModuleFromUrl(null);
        }
      } else {
        let defaultModuleKey = null;
        for (const moduleKey of Object.keys(dataParam.data[section])) {
          for (const child of dataParam.data[section][moduleKey].children) {
            if (child.tag === defaultModuleTab) {
              defaultModuleKey = moduleKey;
              break;
            }
          }
          if (defaultModuleKey !== null) {
            break;
          }
        }

        if (defaultModuleKey === null) {
          defaultModuleKey = dataParam.data[`default-${section}`];
        }

        if (dataParam.data[section][defaultModuleKey] === undefined) {
          const keys = Object.keys(dataParam.data[section]);
          if (keys && keys.length > 0) {
            defaultModuleKey = keys[0];
          }
        }

        setDefaultModule(defaultModuleKey);
        setActiveCategory(defaultModuleKey);
        setResetLeftTextKey(new Date().toTimeString().replace(/[^\d]/g, ''));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultModuleTab]);

  useEffect(() => {
    if (scrollToPoistion && defaultModule && leftDictionary) {
      const el = document.getElementById(scrollToPoistion);
      if (el) {
        setTimeout(function () {
          requestAnimationFrame(() => el.scrollIntoView());
        }, 300);
      }
      setScrollToPoistion(null);
    }
  }, [scrollToPoistion, defaultModule, leftDictionary]);

  const handleShareLinkClicked = (tag) => {
    setCopiedToClipboardTag(tag);
  };

  useEffect(() => {
    if (copiedToClipboardTag === null) {
      return;
    }
    const copiedToClipboardTagTimer = setTimeout(() => {
      setCopiedToClipboardTag(null);
    }, 3000);
    return () => {
      clearTimeout(copiedToClipboardTagTimer);
    };
  }, [copiedToClipboardTag]);

  useEffect(() => {
    if (searchText || searchText === '') {
      if (filterTimer) {
        clearTimeout(filterTimer);
      }
      setFilterTimer(
        setTimeout(
          () => {
            setSearchTermParam({ text: searchText });
            if (searchText === '') {
              setByKeyToggleState(null, false);
            } else {
              setByKeyToggleState(null, true);
            }
          },
          searchText === '' ? 0 : 1000
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const resetSearch = () => {
    setSearchText('');
    if (onResetSearch) {
      onResetSearch();
    }
  };

  const onSearchChanged = () => {
    let newData = null;
    if (searchTermParam.text) {
      var tagRegex = /(<\/?[^>]+>)/g;
      var searchRegex = new RegExp(
        '(' + searchTermParam.text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ')',
        'gi'
      );
      const newSection = {};
      for (const modKey of Object.keys(props.data[section])) {
        const children = props.data[section][modKey].children;
        const newChildren = [];
        const newGroupInfo = {};
        let hasNewGroupInfo = false;
        for (const child of children) {
          const newTopicObj = filterAndHighlight(child.topic, searchRegex, tagRegex);
          const newDescObj = filterAndHighlight(child.desc, searchRegex, tagRegex);
          if (newTopicObj.found === true || newDescObj.found === true) {
            newChildren.push({
              ...child,
              topic: newTopicObj.text,
              desc: newDescObj.text,
            });
            if (
              child.group &&
              !newGroupInfo[child.group] &&
              props.data[section][modKey].groupInfo
            ) {
              newGroupInfo[child.group] = props.data[section][modKey].groupInfo[child.group];
              hasNewGroupInfo = true;
            }
          }
        }

        if (newChildren.length > 0) {
          newSection[modKey] = {
            ...props.data[section][modKey],
            children: newChildren,
            groupInfo: hasNewGroupInfo ? newGroupInfo : undefined,
          };
        }
      }

      newData = {
        ...dataParam.data,
        [section]: newSection,
      };
    } else {
      newData = props.data;
    }

    const temp = {
      ...newData,
      [section]: onApplyDataFilter ? onApplyDataFilter(newData[section]) : newData[section],
    };
    setDataParam({ data: temp });
  };

  useEffect(() => {
    onSearchChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTermParam]);

  const setByKeyToggleState = (key, state) => {
    if (key) {
      setToggleState({ ...toggleState, [key]: state });
      setToggleStateExpandAllClickTracker({ ...toggleStateExpandAllClickTracker, [key]: true });
    } else {
      const tempToggleState = {};
      const tempToggleStateExpandAllClickTracker = {};
      for (const key1 of Object.keys(toggleState)) {
        tempToggleState[key1] = state;
        tempToggleStateExpandAllClickTracker[key1] = true;
      }
      setToggleState(tempToggleState);
      setToggleStateExpandAllClickTracker(tempToggleStateExpandAllClickTracker);
    }
  };

  const filterAndHighlight = (text, searchRegex, tagRegex) => {
    let found = false;

    const newText = text
      .toString()
      .split(tagRegex)
      .map(function (str) {
        if (str.match(tagRegex)) {
          return str;
        } else {
          found = found || searchRegex.test(str);
          return str.replace(searchRegex, "<span class='highlighted'>$1</span>");
        }
      })
      .join('');

    return { text: newText, found };
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return false;
    }
  };

  return (
    defaultModule &&
    leftDictionary && (
      <>
        <PageRow lightGrey={setSearchBackground} py={3} pb={6}>
          <Form key='left-form' className='flex'>
            <Form.Group className={styles.Search} controlId='formSearch' key='input-form-group'>
              <div className={`${styles.InputGroupContainer}`}>
                <InputGroup className={`${styles.InputGroup}`} key='input-input-group'>
                  <FormControl
                    aria-label='search'
                    className={styles.SearchBox}
                    placeholder={'Search'}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText ?? ''}
                    onKeyPress={(e) => handleSearchKeyPress(e)}
                  />
                  <Button
                    aria-label='reset search'
                    className={`button primary ${styles.ResetSearch}`}
                    disabled={!searchText}
                    onClick={(e) => resetSearch()}
                  >
                    Reset
                  </Button>
                </InputGroup>
              </div>
              <div className='height-full'>{props.children}</div>
            </Form.Group>
          </Form>
        </PageRow>
        <PageRow key={`outerContainer-right`}>
          {Object.keys(leftDictionary).length === 0 ? (
            "We couldn't find any matching items to your search. Please try a different search."
          ) : (
            <Tab.Container
              id='left-tabs'
              key={`left-tab-${resetLeftTextKey ?? '-'}`}
              defaultActiveKey={defaultModule}
              activeKey={activeCategory}
            >
              <div
                className={`flex ${phoneView ? 'flex-column' : 'flex-row'} vertical-tabs-container`}
              >
                {phoneView ? (
                  <>
                    <h5>{categoriesLabel}</h5>
                    <AHRQSimpleSelect
                      aria-label='Select Category'
                      className={`mt-2 ${styles.SelectMenu}`}
                      onChange={(selectedObject) => {
                        setActiveCategory(selectedObject.value);
                      }}
                      defaultValueValue={activeCategory}
                      options={Object.keys(leftDictionary).map((key) => {
                        if (leftDictionary[key].children === undefined) {
                          return {
                            value: key,
                            label: `${leftDictionary[key].topic}${
                              leftDictionary[key].childrenCount
                                ? ` (${leftDictionary[key].childrenCount})`
                                : ' (0)'
                            }`,
                          };
                        } else {
                          return {
                            label: `${leftDictionary[key].topic}${
                              leftDictionary[key].childrenCount
                                ? ` (${leftDictionary[key].childrenCount})`
                                : ''
                            }`,
                            options: Object.keys(leftDictionary[key].children).map((innerKey) => {
                              return {
                                value: innerKey,
                                label: `${leftDictionary[key].children[innerKey].topic}${
                                  leftDictionary[key].children[innerKey].childrenCount
                                    ? ` (${leftDictionary[key].children[innerKey].childrenCount})`
                                    : ' (0)'
                                }`,
                              };
                            }),
                          };
                        }
                      })}
                    />
                  </>
                ) : (
                  <div
                    key={`col-1`}
                    className={`${phoneView ? '' : 'pr-5'} vertical-tabs sidebar-column`}
                  >
                    <h5>{categoriesLabel}</h5>
                    <Nav variant='pills' className='vertical-tabs-nav ahrq-accordion-nav'>
                      {Object.keys(leftDictionary).map((key) => {
                        if (leftDictionary[key].children === undefined) {
                          return (
                            <Nav.Item key={`left-${key}`} className='vertical-tabs-nav-item'>
                              <Nav.Link
                                key={key}
                                eventKey={key}
                                onClick={() => setActiveCategory(key)}
                              >
                                {`${leftDictionary[key].topic}${
                                  leftDictionary[key].childrenCount
                                    ? ` (${leftDictionary[key].childrenCount})`
                                    : ' (0)'
                                }`}
                              </Nav.Link>
                            </Nav.Item>
                          );
                        } else {
                          return (
                            <Nav.Item key={`left-${key}`} className='vertical-tabs-nav-item'>
                              <Accordion
                                className={`${styles.CategoryAccordion} secondary`}
                                key={`left-acc-${key}`}
                                defaultActiveKey={
                                  dataParam.data[section][activeCategory]?.category === key
                                    ? key
                                    : 'empty'
                                }
                              >
                                <Accordion.Item eventKey={key} key={`${key}-item`}>
                                  <Accordion.Header
                                    key={`${key}-header`}
                                    className={`${styles.CategoryAccordionHeader}`}
                                    tabIndex={-1}
                                  >
                                    {leftDictionary[key].topic}
                                  </Accordion.Header>
                                  <Accordion.Body key={`${key}-body`}>
                                    {Object.keys(leftDictionary[key].children).map(
                                      (innerKey, index) => (
                                        <div key={index}>
                                          <Nav.Item
                                            key={`${key}-nav-item`}
                                            className='vertical-tabs-nav-item'
                                          >
                                            <Nav.Link
                                              key={`${key}-nav-link`}
                                              eventKey={innerKey}
                                              onClick={() => setActiveCategory(innerKey)}
                                            >
                                              {leftDictionary[key].children[innerKey].topic}
                                              {leftDictionary[key].children[innerKey].childrenCount
                                                ? ` (${leftDictionary[key].children[innerKey].childrenCount})`
                                                : ' (0)'}
                                            </Nav.Link>
                                          </Nav.Item>
                                        </div>
                                      )
                                    )}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Nav.Item>
                          );
                        }
                      })}
                    </Nav>
                  </div>
                )}
                <div className={`${phoneView ? '' : 'pl-3'} content-column`} key={`col-2`}>
                  {searchTermParam.text === '' ? '' : <p>Search Results {resultCount}</p>}
                  <div className='pt-3 pb-2 flex justify-content-between bottom-border mb-4'>
                    <div>
                      <h3 className='my-0 align-items-center'>
                        {dataParam.data[section][activeCategory]?.topic}
                      </h3>
                    </div>
                    {dataParam.data[section][activeCategory]?.groupInfo ? (
                      ''
                    ) : (
                      <div className={styles.ExpandToggleContainer}>
                        <a
                          onClick={(e) => {
                            setByKeyToggleState(activeCategory, !toggleState[activeCategory]);
                            e.preventDefault();
                          }}
                          href='/#'
                        >
                          {toggleState[activeCategory] ? (
                            <>
                              <i className='fa fa-light fa-minus mr-2'></i>Collapse All
                            </>
                          ) : (
                            <>
                              <i className='fa fa-regular fa-plus mr-2'></i>Expand All
                            </>
                          )}
                        </a>
                      </div>
                    )}
                  </div>
                  <Tab.Content>
                    <>
                      {dataParam.data[section][activeCategory]?.groupInfo && (
                        <div className={styles.JumpToUtility}>
                          <label className={`text-xs text-bold ${styles.JumpToLabel} `}>
                            Jump to:
                          </label>
                          <ul className={styles.Ul}>
                            {Object.keys(dataParam.data[section][activeCategory].groupInfo).map(
                              (qi, index) => (
                                <li key={`${qi}-${index}`} className={styles.Ul}>
                                  <Link
                                    className='link-button small'
                                    to='#'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setScrollToPoistion(`pane-${activeCategory}-${qi}`);
                                    }}
                                  >
                                    {dataParam.data[section][activeCategory].groupInfo[qi].title}
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                      {Object.keys(dataParam.data[section]).map((moduleName) => (
                        <Tab.Pane
                          key={`pane-${moduleName}-${toggleState[moduleName] ? 'op' : 'cl'}`}
                          eventKey={`${moduleName}`}
                        >
                          {Object.keys(
                            dataParam.data[section][moduleName].groupInfo ?? { '': '' }
                          ).map((groupInfoKey) => (
                            <div key={groupInfoKey}>
                              {groupInfoKey !== '' ? (
                                <div
                                  id={`pane-${moduleName}-${groupInfoKey}`}
                                  className='pt-3 pb-2 d-flex justify-content-between bottom-border mb-2 mt-10'
                                >
                                  <div>
                                    <h3 className='my-0 d-flex align-items-center'>
                                      {
                                        dataParam.data[section][moduleName].groupInfo[groupInfoKey]
                                          .title
                                      }
                                    </h3>
                                  </div>
                                  <div className={styles.ExpandToggleContainer}>
                                    <a
                                      onClick={(e) => {
                                        setByKeyToggleState(
                                          groupInfoKey,
                                          !toggleState[groupInfoKey]
                                        );
                                        e.preventDefault();
                                      }}
                                      href='/#'
                                    >
                                      {toggleState[groupInfoKey] ? (
                                        <>
                                          <i className='fa fa-light fa-minus mr-2'></i>Collapse All
                                        </>
                                      ) : (
                                        <>
                                          <i className='fa fa-regular fa-plus mr-2'></i>Expand All
                                        </>
                                      )}
                                    </a>
                                  </div>
                                </div>
                              ) : null}
                              {dataParam.data[section][moduleName].children
                                .filter(
                                  (child) => groupInfoKey === '' || child.group === groupInfoKey
                                )
                                .map((child, paneIndex) => {
                                  let activeKey = 'empty';
                                  if (toggleState[groupInfoKey || moduleName]) {
                                    activeKey = child.tag;
                                  } else if (defaultModuleTab === child.tag 
                                    && !toggleStateExpandAllClickTracker[groupInfoKey || moduleName]) {
                                    activeKey = child.tag;
                                  } else if (
                                    defaultModuleTab === 'empty' &&
                                    expandFirstItem === true &&
                                    paneIndex === 0
                                    && !toggleStateExpandAllClickTracker[groupInfoKey || moduleName]
                                  ) {
                                    activeKey = child.tag;
                                  }
                                  return (
                                    <div key={`pane-${moduleName}-${paneIndex}-${child.tag}`}>
                                      <Tab.Pane
                                        key={`pane-${moduleName}-${groupInfoKey}-${
                                          toggleState[groupInfoKey] ? 'op' : 'cl'
                                        }`}
                                      >
                                        <Accordion
                                          id={`pane-acc-${child.tag}`}
                                          key={`pane-acc-${moduleName}-${paneIndex}-${child.tag}`}
                                          defaultActiveKey={activeKey}
                                        >
                                          <Accordion.Item
                                            key={child.tag}
                                            id={`item-acc-${moduleName}-${paneIndex}-${child.tag}`}
                                            eventKey={child.tag}
                                          >
                                            <Accordion.Header>
                                              <div className={styles.AccordionHeading}>
                                                <div>
                                                  <span className='text-tag-small'>
                                                    {child.subTopic}
                                                  </span>
                                                  <div
                                                    className={`${styles.AccordionHeadingMain}`}
                                                    dangerouslySetInnerHTML={{
                                                      __html: child.topic,
                                                    }}
                                                  ></div>
                                                  <span className={styles.AccordionHeadingTertiary}>
                                                    {child.underTopic}
                                                  </span>
                                                </div>

                                                <div>
                                                  {copiedToClipboardTag === child.tag ? (
                                                    <div className={styles.CopyContainer}>
                                                      <i className='fa fa-check-circle flex green'></i>
                                                      <div className={`${styles.CopiedText} flex`}>
                                                        Copied to Clipboard
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <CopyToClipboard
                                                      text={`${window.location.origin}${window.location.pathname}?tag=${child.tag}`}
                                                    >
                                                      <div
                                                        className={styles.CopyBtn}
                                                        onClick={(e) => {
                                                          handleShareLinkClicked(child.tag);
                                                          e.stopPropagation();
                                                        }}
                                                      >
                                                        <a
                                                          onClick={(e) => {
                                                            handleShareLinkClicked(child.tag);
                                                            e.preventDefault();
                                                          }}
                                                          href='/#'
                                                          tabIndex={-1}
                                                        >
                                                          <div className='btn search-share-btn'>
                                                            Share
                                                          </div>
                                                        </a>
                                                      </div>
                                                    </CopyToClipboard>
                                                  )}
                                                </div>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className={styles.accordionContent}>
                                                <div>
                                                  <div
                                                    style={{ wordBreak: 'break-word' }}
                                                    dangerouslySetInnerHTML={{ __html: child.desc }}
                                                  />
                                                </div>
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </Tab.Pane>
                                    </div>
                                  );
                                })}
                            </div>
                          ))}
                        </Tab.Pane>
                      ))}
                    </>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          )}
        </PageRow>
      </>
    )
  );
};

export default SearchableAccordion;
