import React from 'react';
import { Link } from 'react-router-dom';
import styles from './home.module.scss';
import { Newsletter } from '../Common';
import { GetToKnowAHRQ, Hero, LatestNews, PowerOfQIs, QIsInAction } from './Sections';
import HPQIAIcon from '../../img/HPQIA.png';
import IAFSIcon from '../../img/IAFS.png';
import TCOIcon from '../../img/TCO.png';
import LaptopImg from '../../img/laptop-tech-specs.png';
import { Flex, PageRow } from '../../ui';
import PeopleSayCloudQI from '../Software/PeopleSayCloudQI';

const Home = () => {
  return (
    <div className={styles.Home}>
      <Hero />

      <GetToKnowAHRQ />

      <PageRow lightGrey>
        <Flex margin='0 auto' maxWidth={800} textAlign='center'>
          <h2>
            Bringing <span className='color-primary-1 text-italic'>excellence</span> to healthcare
            decision making, quality improvement, and research
          </h2>
          <Flex cols='sm' gap={6} mt={6} alignItems={{ xs: 'center', sm: 'flex-start' }}>
            <Flex
              gap={3}
              justifyContent='flex-start'
              alignItems='center'
              maxWidth={{ xs: 300, md: 'auto' }}
            >
              <img alt='HPQIA Icon' src={HPQIAIcon} height={79} width={79} />
              <p className='text-large'>Highlight potential quality improvement areas</p>
            </Flex>
            <Flex
              gap={3}
              justifyContent='flex-start'
              alignItems='center'
              maxWidth={{ xs: 300, md: 'auto' }}
            >
              <img alt='TCO Icon' src={TCOIcon} height={79} width={79} />
              <p className='text-large'>Track changes over time</p>
            </Flex>
            <Flex
              gap={3}
              justifyContent='flex-start'
              alignItems='center'
              maxWidth={{ xs: 300, md: 'auto' }}
            >
              <img alt='IAFS Icon' src={IAFSIcon} height={79} width={79} />
              <p className='text-large'>Identify areas for further study</p>
            </Flex>
          </Flex>
        </Flex>
      </PageRow>

      <PageRow py={8}>
        <Flex flexDirection={{ xs: 'column-reverse', lg: 'row' }} gap={4}>
          <Flex>
            <h2>Explore the Quality Indicator Technical Specifications</h2>
            <p>
              Technical Specifications break down calculations used to formulate each indicator,
              including a brief description of the measure, numerator and denominator information,
              and details on cases that should be excluded from calculations.
            </p>
            <ul className='arrows'>
              <li>
                <Link to='/measures/MHI_TechSpec' aria-label='MHI Specifications'>
                  MHI <sup className='color-primary-1'>BETA</sup> Technical Specifications -{' '}
                  <span className={styles.New}>New!</span>
                </Link>
              </li>
              <li>
                <Link role='button' to='/measures/PQE_TechSpec' aria-label='ED PQI Specification'>
                  PQE Technical Specifications
                </Link>
              </li>
              <li>
                <Link to='/measures/PQI_TechSpec' aria-label='PQI Specifications'>
                  PQI Technical Specifications
                </Link>
              </li>
              <li>
                <Link to='/measures/IQI_TechSpec' aria-label='IQI Specifications'>
                  IQI Technical Specifications
                </Link>
              </li>
              <li>
                <Link to='/measures/PSI_TechSpec' aria-label='PSI Specifications'>
                  PSI Technical Specifications
                </Link>
              </li>
              <li>
                <Link role='button' to='/measures/PDI_TechSpec' aria-label='PDI Specification'>
                  PDI Technical Specifications
                </Link>
              </li>
            </ul>
          </Flex>
          <Flex>
            <img src={LaptopImg} alt='How Measures Used Home' style={{ width: '100%' }} />
          </Flex>
        </Flex>
      </PageRow>

      <QIsInAction />

      <PowerOfQIs />

      <PageRow babyBlue>
        <PeopleSayCloudQI />
      </PageRow>

      <Newsletter />

      <LatestNews />
    </div>
  );
};

export default Home;
