import React from 'react';
import { Box, styled } from '@mui/material';
import {
  Button,
  Flex,
  PageHeader,
  PageRow,
  SoftwareList,
  SoftwareListItem,
  Tile,
  TileGroup,
} from '../../ui';
import { getReleaseItem } from '../../data/releases';

const QI = () => {
  const cloudQiRelease = getReleaseItem('CloudQi', 'CLOUDQI');
  const cloudQiPsiInfoSheet = getReleaseItem('PsiInfoSheet', 'CLOUDQI');
  const qiRateComparison = getReleaseItem('AHRQQIRateComparison');
  const releaseFaq = getReleaseItem('Icd10Faq');
  const sasAndWinQiComparisonTesting = getReleaseItem('SASAndWinQIComparisonTesting');

  const softwareRightContents = [
    {
      text: 'Leverage hospital inpatient administrative data to identify quality of care events',
    },
    {
      text: 'Leverage hospital inpatient administrative data',
    },
    {
      text: 'Windows 64-bit version available',
      sasqi: false,
    },
    {
      text: 'Powerful SAS/STAT software integration',
      winqi: false,
    },
    {
      text: 'Robust point-and-click user-interface',
      sasqi: false,
    },
    {
      text: 'Completely free ',
    },
    {
      text: 'Updated annually',
    },
    {
      text: 'Complementary technical support',
    },
  ];
  return (
    <>
      <PageHeader.Row purple bgSrc='/images/hex-purple.png'>
        <PageHeader.Title>Software</PageHeader.Title>
        <PageHeader.Content>
          Putting the power of AHRQ Quality Indicators in your hands. AHRQ oﬀers free software to
          help users using the AHRQ QIs generate results that are both accurate and actionable. Use
          of this free software ensures a standard, trusted approach to quality measurement and
          means more resources are available for supporting improvements to patient care.
        </PageHeader.Content>
      </PageHeader.Row>

      <PageRow className='explore-qi-row'>
        <Flex gap={2}>
          <h2 className='align-center'>AHRQ QI Software</h2>
          <p className='align-center'>
            AHRQ offers free software to help users using the AHRQ QIs generate results that are
            both accurate and actionable. Use of this free software ensures a standard, trusted
            approach to quality measurement and means more resources are available for supporting
            improvements to patient care.
          </p>
          <TileGroup type='measures' columns={{ xs: 2, md: 4, lg: 4, xl: 6 }} gap='sm' py={3}>
            <Tile
              title='SAS QI v2024'
              description='Ideal for advanced users to run in a SAS/STAT software package.'
              to='/software/sas_qi'
              toLabel='Now available, SAS QI v2024.0.1'
            />
            <Tile
              title='WinQI v2024'
              description={`WinQI's robust user interface is ideal for non-programmers.`}
              to='/software/win_qi'
              toLabel='Now available, WinQI v2024.0.1'
            />
            <Tile
              title='CloudQI v2024'
              description={cloudQiRelease.desc}
              to='/software/cloudqi'
              toLabel={`CloudQI v2024.0.2 now supports PSI, PQE and MHI modules`}
              buttonTo={cloudQiPsiInfoSheet.url}
              buttonLabel={`Learn More ${cloudQiPsiInfoSheet.info}`}
              mdOffset={1}
              xlOffset={0}
            />
          </TileGroup>
          <Box fontSize={12} textAlign='center'>
            Looking for archived software? Find the archived versions{' '}
            <a href='/archive/software'> here</a>
          </Box>
        </Flex>
      </PageRow>

      <PageRow className='software-comparison-row' py={6} pt={3}>
        <h3 className='d-md-none pb-5 text-center'>Find the right software for you</h3>
        <ComparisonTable>
          <thead>
            <tr>
              <th className='header'>
                <span className='d-none d-md-block'>Find the right software for you</span>
              </th>
              <th className='sas'>
                SAS QI
                <small>Ideal for researchers and advanced users</small>
              </th>
              <th className='win'>
                WinQI
                <small>Ideal for health care professionals</small>
              </th>
            </tr>
          </thead>
          <tbody>
            {softwareRightContents.map((r) => (
              <tr key={r.text}>
                <td>{r.text}</td>
                <td className='text-center'>
                  {r.sasqi !== false && <i aria-label={r.text} className='fa fa-check primary'></i>}
                </td>
                <td className='text-center'>
                  {r.winqi !== false && <i aria-label={r.text} className='fa fa-check primary'></i>}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>Choose a version</td>
              <td className='text-center'>
                <Button href='/software/sas_qi' size='small' arrow>
                  Get SAS QI
                </Button>
              </td>
              <td className='text-center'>
                <Button href='/software/win_qi' size='small' arrow>
                  Get WinQI
                </Button>
              </td>
            </tr>
          </tfoot>
        </ComparisonTable>
      </PageRow>

      <PageRow className='software-testing-row' lightGrey>
        <SoftwareList title='Software Testing'>
          <SoftwareListItem
            title={sasAndWinQiComparisonTesting.title}
            subtitle={sasAndWinQiComparisonTesting.revision}
            url={sasAndWinQiComparisonTesting.url}
            label={sasAndWinQiComparisonTesting.cta}
            ariaLabel={sasAndWinQiComparisonTesting.aria}
          />
          <SoftwareListItem
            title={qiRateComparison.title}
            subtitle={qiRateComparison.revision}
            url={qiRateComparison.url}
            label={qiRateComparison.cta}
            ariaLabel={qiRateComparison.aria}
          />
        </SoftwareList>
        <Box fontSize={12} textAlign='center' pt={2}>
          <span style={{ color: '#CF0021' }}>Note:</span> To learn frequently asked questions,
          access the{' '}
          <a href={releaseFaq.url} rel='noreferrer' target='_blank'>
            v2024 software release FAQ document {releaseFaq.info}
          </a>
          .
        </Box>
      </PageRow>
    </>
  );
};

export default QI;

const ComparisonTable = styled('table')(({ theme }) => ({
  borderColor: '#E0EBF2',
  borderRadius: '50%',
  lineHeight: 1.1,
  verticalAlign: 'top',
  width: '100%',

  'tr:nth-of-type(odd)': {
    backgroundColor: theme.palette.fill.lightGrey,
  },

  thead: {
    tr: {
      th: {
        backgroundColor: theme.palette.fill.purple,
        color: theme.palette.common.white,
        fontSize: 23,
        fontWeight: 700,

        padding: '.75rem 1rem',
        '&.header': {
          borderTopLeftRadius: '1.25rem',
          fontSize: 28,
          paddingRight: '2rem',
          paddingLeft: '2rem',
        },
        '&.sas, &.win': {
          textAlign: 'center',
          width: 188,
        },
        '&.sas': {
          backgroundColor: theme.palette.secondary.main,
        },
        '&.win': {
          backgroundColor: theme.palette.primary.main,
          borderTopRightRadius: '1.25rem',
        },
        small: {
          display: 'block',
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.25,
          marginTop: theme.spacing(0.5),
        },
      },
    },
  },

  tbody: {
    borderRightWidth: 1,
    borderLeftWidth: 1,
    tr: {
      td: {
        borderRightWidth: 1,
        borderLeftWidth: 1,
        padding: '1rem 2rem',
        '.fa-check': {
          color: theme.palette.success.light,
          fontSize: 24,
        },
      },
    },
  },

  tfoot: {
    tr: {
      td: {
        borderWidth: 1,
        padding: '1rem 2rem',
        fontWeight: 700,
      },
    },
  },
}));
