import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import QIResources from '../../components/Measures/QIResources/QIResources';
import PQIResources from '../../components/Measures/QIResources/PQIResources';
import IQIResources from '../../components/Measures/QIResources/IQIResources';
import PSIResources from '../../components/Measures/QIResources/PSIResources';
import PDIResources from '../../components/Measures/QIResources/PDIResources';
import MHIResources from '../../components/Measures/QIResources/MHIResources';
import { PQEResources, PQEResourcesHowTo } from '../../components/Measures/QIResources';
import AllMeasures from '../../components/Measures/AllMeasures/AllMeasures';
import CompositeWorkgroup from '../../components/Measures/CompositeWorkgroup';
import CodeLog from '../../components/Measures/CodeLog/CodeLog';
import NQFEndorsedMeasure from '../../components/Measures/NQFEndorsedMeasure';
import TechSpec from '../../components/Measures/TechSpecs/TechSpec';
import MHIFederalWorkgroupThankYou from '../../components/Measures/MHIFederalWorkgroupThankYou';

const Measures = () => {
  const params = useParams();
  const section = params?.section?.toLowerCase();
  const subsection = params?.subsection?.toLowerCase();
  const subsubsection = params?.subsubsection?.toLowerCase();

  const pageTitles = {
    pqe_resources: 'AHRQ QI: Prevention Quality Indicators in Emergency Settings Overview',
    pqi_resources: 'AHRQ QI: Prevention Quality Indicators in Inpatient Settings Overview',
    iqi_resources: 'AHRQ QI: Inpatient Quality Indicators Overview',
    psi_resources: 'AHRQ QI: Patient Safety Indicators Overview',
    pdi_resources: 'AHRQ QI: Pediatric Quality Indicators Overview',
    mhi_resources: 'AHRQ QI: Maternal Health Indicators Overview',
    pqe_techspec: 'AHRQ QI: PQE Technical Specifications Updates',
    pqi_techspec: 'AHRQ QI: PQI Technical Specifications Updates',
    iqi_techspec: 'AHRQ QI: IQI Technical Specifications Updates',
    psi_techspec: 'AHRQ QI: PSI Technical Specifications Updates',
    pdi_techspec: 'AHRQ QI: PDI Technical Specifications Updates',
    all_measures: 'AHRQ QI: All Indicators Resources',
    composite_workgroup: 'AHRQ QI: Composite Workgroups',
    mhi_federal_workgroup_thankyou: 'AHRQ QI: Maternal Health Indicators Federal Workgroup',
    pdi_log_coding_updates: 'AHRQ QI: PDI Log of Coding Updates and Revisions',
    psi_log_coding_updates: 'AHRQ QI: PSI Log of Coding Updates and Revisions',
    iqi_log_coding_updates: 'AHRQ QI: IQI Log of Coding Updates and Revisions',
    pqi_log_coding_updates: 'AHRQ QI: PQI Log of Coding Updates and Revisions',
    list_ahrq_qi: 'AHRQ QI: National Quality Forum',
    nqf_endorsed_measures: 'AHRQ QI: National Quality Forum',
    default: 'AHRQ QI: Quality Indicator Resources',
  };

  useEffect(() => {
    const keys = [section, subsection, subsubsection];
    let repo = pageTitles;
    let title = null;
    for (const key of keys) {
      const lowerKey = key?.toLowerCase();
      if (repo[lowerKey] === undefined) {
        break;
      } else {
        title = repo[lowerKey];
        repo = repo[lowerKey];
      }

      if (typeof title === 'string') {
        break;
      }
    }

    document.title = title ? title : pageTitles['default'];
  });

  if (section === 'pqe_resources') return <PQEResources />;
  else if (section === 'how_to_use_pqe_resources') return <PQEResourcesHowTo />;
  else if (section === 'pqi_resources') return <PQIResources />;
  else if (section === 'iqi_resources') return <IQIResources />;
  else if (section === 'psi_resources') return <PSIResources />;
  else if (section === 'pdi_resources') return <PDIResources />;
  else if (section === 'mhi_resources') return <MHIResources />;
  else if (section === 'pqe_techspec') return <TechSpec module={'pqe'} version={'current'} />;
  else if (section === 'pqi_techspec') return <TechSpec module={'pqi'} version={'current'} />;
  else if (section === 'pdi_techspec') return <TechSpec module={'pdi'} version={'current'} />;
  else if (section === 'psi_techspec') return <TechSpec module={'psi'} version={'current'} />;
  else if (section === 'iqi_techspec') return <TechSpec module={'iqi'} version={'current'} />;
  else if (section === 'mhi_techspec') return <TechSpec module={'mhi'} version={'current'} />;
  else if (section === 'all_measures') return <AllMeasures />;
  else if (section === 'composite_workgroup') return <CompositeWorkgroup />;
  else if (section === 'mhi_federal_workgroup_thankyou') return <MHIFederalWorkgroupThankYou />;
  else if (section === 'pdi_log_coding_updates')
    return <CodeLog module={'pdi'} version={subsection} />;
  else if (section === 'psi_log_coding_updates')
    return <CodeLog module={'psi'} version={subsection} />;
  else if (section === 'iqi_log_coding_updates')
    return <CodeLog module={'iqi'} version={subsection} />;
  else if (section === 'pqi_log_coding_updates')
    return <CodeLog module={'pqi'} version={subsection} />;
  else if (section === 'pqe_log_coding_updates')
    return <CodeLog module={'pqe'} version={subsection} />;
  else if (section === 'list_ahrq_qi' || section === 'nqf_endorsed_measures')
    return <NQFEndorsedMeasure />;
  else return <QIResources />;
};
export default Measures;
