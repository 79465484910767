import React from 'react';
import { Card, Col } from 'react-bootstrap';
import styles from './QICard.module.scss';
import { Button } from '../../../ui';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const QICard = ({ info }) => {
  const url = info.url || info.href || info.to;
  const fileExt = url?.toLowerCase().split('.').pop() ?? undefined;
  const isFile = ['xlsx', 'pdf', 'zip'].includes(fileExt);
  const target = isFile || info.newTab ? '_blank' : '_self';

  return (
    <Col className={styles.Container} xs={12} sm={6} md={4}>
      <Card className={styles.Card}>
        <Card.Header className={styles.Header}>
          <a href={url} className='text-white' target={target} rel='noreferrer'>
            <h6>{info.title}</h6>
          </a>
        </Card.Header>
        <Card.Body className={styles.Body}>
          <div dangerouslySetInnerHTML={{ __html: info.desc }} />
          {info.linkUrl && (
            <a href={info.linkUrl} className={styles.Link}>
              {info.linkLabel} <ChevronRightIcon fontSize='10' />
            </a>
          )}
        </Card.Body>
        <div className={`m-4 ${styles.CardButtonContainer}`}>
          <Button aria-label={info.aria} href={url} fullWidth>
            {info.cta}
          </Button>
        </div>
      </Card>
    </Col>
  );
};

export default QICard;
