import React from 'react';
import TopBanner from '../Common/TopBanner/TopBanner';
import { AltButton, BlockQuote, PageRow, SoftwareList, SoftwareListItem } from '../../ui';
import { styled } from '@mui/material';

const MHIFederalWorkgroupThankYou = () => {
  return (
    <>
      <TopBanner>
        <ThankYou>Thank You!</ThankYou>
        <h4>Maternal Health Indicators (MHI) Federal Workgroup</h4>
      </TopBanner>

      <PageRow py={0} pt={6} sx={{ fontSize: 18 }}>
        <p>
          AHRQ's Maternal Health Indicators was developed in collaboration with a Department of
          Health and Human Services interagency workgroup that{' '}
          <strong>
            included subject matter expert representatives from the following organizations:
          </strong>
        </p>

        <ul>
          <li>
            <strong>Health Resources and Services Administration (HRSA)</strong>
          </li>
          <li>
            <strong>Centers for Disease Control (CDC)</strong>
          </li>
          <li>
            <strong>Office of the Assistant Secretary of Health (OASH)</strong>
          </li>
          <li>
            <strong>Office of the Assistant Secretary for Planning and Evaluation (ASPE)</strong>
          </li>
        </ul>

        <p>
          The workgroup collaborated over several meetings throughout 2024 while AHRQ also
          coordinated with the Centers for Medicare & Medicaid Services (CMS) Center for Clinical
          Safety & Quality (CCSQ). Using administrative data from the Healthcare Cost and
          Utilization Project (HCUP), AHRQ assessed potential refinements to the definition of
          coagulopathy (including DIC) and renal failure and obtained feedback from these federal
          agencies and subject matter experts on the implementation of these refinements.
        </p>
      </PageRow>

      <PageRow py={0} pt={4}>
        <BlockQuote>
          <h4>
            AHRQ wishes to express appreciation to the workgroup members who contributed to the
            effort.
          </h4>
          <AltButton
            aria-label='View MHI Page'
            label='Learn More About MHI'
            href='/measures/mhi_resources'
            arrow
            inverse
          />
        </BlockQuote>
      </PageRow>

      <PageRow py={0} pt={4} pb={8}>
        <SoftwareList title='Related Blog' subtitle='' paper>
          <SoftwareListItem
            title='New Healthcare Quality Indicator from AHRQ Aimed at Addressing Maternal Morbidities'
            subtitle='November 2024'
            url='https://www.ahrq.gov/news/blog/ahrqviews/maternal-health-indicators.html'
            label='Read the Blog Post'
            ariaLabel='Read Blog Post'
          />
        </SoftwareList>
      </PageRow>
    </>
  );
};

const ThankYou = styled('h1')(({ theme }) => ({
  backgroundImage: 'url(/images/script-thank-you.svg)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '303px 92px',
  height: 92,
  marginBottom: '10px !important',
  minWidth: 303,
  textIndent: -9999,
}));

export default MHIFederalWorkgroupThankYou;
