import { getReleaseItem } from './releases';

const psi08Announcement = getReleaseItem('Psi08ExpansionAnnouncement', undefined, 'v2023');

export const newsData = {
  'default-categories': 'all',
  'all-categories': 'all',
  categories: [
    {
      category: 'all',
      title: 'All News',
    },
    {
      category: 'software',
      title: 'Software Releases and Updates',
    },
    {
      category: 'indicator',
      title: 'Indicator Changes',
    },
    {
      category: 'other',
      title: 'Other',
    },
  ],
  items: [
    {
      title: 'Release of AHRQ CloudQI v2024.0.2 Software.',
      id: 'software-release-2024-11-20',
      date: 'November 20, 2024',
      desc: `<p>This is a minor release of AHRQ Software to address user reported bugs. Details are in the <a href="/Downloads/Software/WinQI/V2024/AHRQ_Windows_v2024.0.2_Software_Rel_Notes.pdf" target="_blank">Software Release Notes for Windows</a> (PDF File, 404 KB)</p>`,
      url: '/software/cloudqi',
      category: ['software'],
      isNew: true,
    },
    {
      title: 'AHRQ Seeking Members for Maternal Health Indicators Workgroup.',
      id: 'announcment-2024-10-25',
      date: 'October 25, 2024',
      desc: `<p>AHRQ is recruiting clinical experts and patient/family advocates to serve on a Maternal Health Indicators (MHI) Expert Work Group (EWG). Read AHRQ’s <a href="/announcements/2024/10">announcement</a> to learn more.</p>`,
      url: '/announcements/2024/10',
      category: ['other'],
    },
    {
      title: 'Release of AHRQ SAS QI, WinQI, and CloudQI v2024.0.1 Software.',
      id: 'software-release-2024-09-23',
      date: 'September 23, 2024',
      desc: `<p>This is a minor release of the AHRQ QI Software to introduce the Maternal Health Indicators (MHI) module and other software improvements. Details are in the <a href="/Downloads/Software/WinQI/V2024/AHRQ_Windows_v2024.0.1_Software_Rel_Notes.pdf">Software Release Notes for Windows</a> (PDF File, 345 KB) and <a href="/Downloads/Software/SAS/V2024/AHRQ_SASQI_v2024.0.1_Software_Rel_Notes.pdf">SAS QI</a> (PDF File, 167 KB).</p>`,
      url: '/software/qi',
      category: ['software'],
    },
    {
      title: 'Beta release of a new module: Maternal Health Indicators (MHI), 2024.',
      id: 'mhi-release-2024-09-23',
      date: 'September 23, 2024',
      desc: `<p>The MHI module aims to broadly address healthcare quality in the domain of maternal health and identify opportunities to reduce complications during the peripartum period. Read AHRQ's <a href="/Downloads/Resources/v2024_MHI_Beta_Software_Announcement.pdf">announcement<a/a> (PDF File, 228 KB) to learn more.</p>`,
      url: '/software/qi',
      category: ['software'],
    },
    {
      title: 'Release of AHRQ SAS QI, WinQI, and CloudQI v2024 Software.',
      id: 'software-release-2024-07-26',
      date: 'July 26, 2024',
      desc: `<p>The software allows organizations to apply the AHRQ Quality Indicators (QIs) to their own data to assist quality improvement efforts in acute hospital settings.</p>`,
      url: '/software/qi',
      category: ['software'],
    },
    {
      title: 'Release of ED PQI Beta v2023.0.1 and CloudQI PSI Beta v2023.0.1.',
      id: 'software-release-2023-12-21',
      date: 'December 21, 2023',
      desc: `<p>This is a minor release of Windows ED PQI Beta and CloudQI PSI Beta applications. Details are in the <a href='https://qualityindicators.ahrq.gov/Downloads/Software/WinQI/V2023/AHRQ_EDPQI_Beta_v2023.0.1_Software_Rel_Notes.pdf' target='_blank'>Software Release Notes for Windows ED PQI Beta</a> (PDF File, 262 KB) and <a href='https://qualityindicators.ahrq.gov/Downloads/Software/CloudQI_PSI_Beta/AHRQ_CloudQI_PSI_Beta_v2023.0.1_Software_Rel_Notes.pdf' target='_blank'>CloudQI PSI Beta</a> (PDF File, 189 KB).</p>`,
      url: '/software/qi',
      category: ['software'],
    },
    {
      title:
        'Beta release of a new module: The Emergency Department Prevention Quality Indicators (ED PQI) v2023 software.',
      id: 'software-release-2023-09-22',
      date: 'September 22, 2023',
      desc: `<p>The beta software allows organizations to apply the Emergency Department's (ED) AHRQ Quality Indicators (QIs) to their own treat-and-release cases from the ED and inpatient discharges admitted through the ED.</p>`,
      url: '/measures/pqe_resources',
      category: ['software'],
    },
    {
      title: 'Release of AHRQ QI SAS QI, WinQI, and CloudQI v2023 software.',
      id: 'software-release-2023-08-14',
      date: 'August 14, 2023',
      desc: '<p>The software allows organizations to apply the AHRQ Quality Indicators (QIs) to their own data to assist quality improvement efforts in acute hospital settings.</p>',
      url: '/software/qi',
      category: ['software'],
    },
    {
      title: `${psi08Announcement.title} ${psi08Announcement.info}`,
      id: psi08Announcement.name,
      date: psi08Announcement.date,
      desc: '<p>' + psi08Announcement.desc + '</p>',
      url: psi08Announcement.url,
      target: '_blank',
      category: ['software'],
    },
    {
      title:
        'Summary of the Listening Session held by Agency for Healthcare Research and Quality  (AHRQ) in July 2022 with users of AHRQ Quality Indicators (QIs). (PDF File, 264 KB)',
      id: 'summary-listening-oct-2022',
      date: 'October 18, 2022',
      desc: '<p>In July 2022, the Agency for Healthcare Research and Quality (AHRQ) held a listening session with users of AHRQ Quality Indicators (QIs), measures of health care quality designed for use by program managers, researchers, and others interested in health care quality measurement.</p>',
      url: '/Downloads/Resources/Summary_of_July_Listening_Session.pdf',
      category: ['other'],
    },
    {
      title: 'Release of WINQI v2022.0.1 and SAS QI v2022.0.1.',
      id: 'software20220919',
      date: 'September 19, 2022',
      desc: "<p>This is a minor release of SAS QI and WinQI to update IQI, PSI, and PDI modules. Also includes some additional fixes in WinQI. Details are in the Software Release Notes for <a href='/Downloads/Software/SAS/V2022/AHRQ_SASQI_v2022.0.1_Software_Rel_Notes.pdf'>SAS QI</a> (PDF File, 234 KB) and <a href='/Downloads/Software/WinQI/V2022/AHRQ_WinQI_v2022.0.1_Software_Rel_Notes.pdf'>WinQI</a> (PDF File, 296 KB).</p>",
      url: '/software',
      category: ['software'],
    },
    {
      title: 'Release of AHRQ QI SAS QI and WinQI v2022 ICD-10-CM/PCS Software.',
      id: 'software20220729',
      date: 'July 29, 2022',
      desc: '<p>The software allows organizations to apply the AHRQ Quality Indicators (QIs) to their own data to assist quality improvement efforts in acute hospital settings.</p>',
      url: '/software',
      category: ['software'],
    },
    {
      title: 'Release of WINQI v2021.0.1 and SAS QI v2021.0.2 Software.',
      id: 'software20220328',
      date: 'March 28, 2022',
      desc: "<p>This is a minor release to update the IQI module that fixes an issue with the calculation of admission-based APR-DRGs. See the <a href='/Downloads/Resources/AHRQ_QI_v2021_IQI_APRDRG_User_Note_03_2022.pdf'>User Note (PDF File, 114 KB)</a>.</p>",
      url: '/software',
      category: ['software'],
    },
    {
      title: 'Release of SAS QI v2021.0.1 Software.',
      id: 'software20210913',
      date: 'September 13, 2021',
      desc: '<p>This is a minor release to update IQI, PSI, and PDI packages that fixes an issue that resulted in no COVID-19 diagnosis-based exclusions for 2020 data.</p>',
      url: '/software/sas_qi',
      category: ['software'],
    },
    {
      title:
        'Updated SAS QI software packages (IQI, PSI, and PDI) will be published on September 13, 2021 to resolve an issue with COVID-19 exclusion logic with 2020 data only. (PDF File, 131 KB)',
      id: 'updated-sas-package-2021',
      date: 'September 8, 2021',
      url: '/Downloads/Resources/SAS_QI_Release_Memo_COVID-19-Fix_v2021.pdf',
      target: '_blank',
      category: ['other'],
    },
    {
      title: 'Release of AHRQ QI SAS QI and WinQI v2021 ICD-10-CM/PCS Software.',
      id: 'software20210730',
      desc: 'The software allows organizations to apply the AHRQ Quality Indicators (QIs) to their own data to assist quality improvement efforts in acute hospital settings.',
      date: 'July 30, 2021',
      url: '/Software',
      category: ['software'],
    },
    {
      title: 'Learn COVID-19 impact on AHRQ QI software (PDF File, 223 KB)',
      id: 'others-covid-2021',
      date: 'July 30, 2021',
      desc: '',
      url: '/Downloads/Resources/COVID19_UserNote_July2021.pdf',
      target: '_blank',
      category: ['other'],
    },
    {
      title:
        'AHRQ will no longer seek NQF re-endorsement for its portfolio of measures in the AHRQ Quality Indicators (QIs) program starting in fiscal year 2022. (PDF File, 95 KB)',
      id: 'others-1',
      date: 'May 18, 2021',
      desc: '',
      url: '/Downloads/News/AHRQ_Rationale4notseekingNQFendorsement-May2021.pdf',
      target: '_blank',
      category: ['other'],
    },
    {
      title:
        'AHRQ publishes the explanation on PSI 03 (Pressure Ulcer Rate) measure logic used in v2020 QI software. (PDF File, 78 KB)',
      id: 'psi03-02-2021',
      date: 'February 17, 2021',
      desc: '',
      url: '/News/AHRQ_QI_v2020_PSI03_User_Note_02_2021.pdf',
      target: '_blank',
      category: ['indicator'],
    },
    {
      title: 'Learn the impact of COVID-19 on the AHRQ Quality Indicators. (PDF File, 135 KB)',
      id: 'others-2',
      date: 'November 23, 2020',
      desc: '',
      url: '/Downloads/Resources/COVID19_UserNote_Oct2020.pdf',
      target: '_blank',
      category: ['other'],
    },
    {
      title: 'Release of WinQI v2020.0.1 Software.',
      id: 'software-1',
      date: 'October 28, 2020',
      desc: "<p>This minor release is an update to the v2020 WinQI software that was released on July 31, 2020. This fixes issues around POA exclusions in computing rates for some Patient Safety Indicators (PSI) and Pediatric Quality Indicators (PDI) when using FY 2021 coded discharges.<br/><a href='/Downloads/Software/WinQI/V2020/AHRQ_WinQI_v2020.0.1_Software_Rel_Notes.pdf'>Release Note (PDF File, 379 KB)</a></p>",
      url: '/Software',
      category: ['software'],
    },
    {
      title: 'Learn COVID-19 impact on AHRQ QI software. (PDF File, 107 KB)',
      id: 'others-3',
      date: 'July 31, 2020',
      desc: '',
      url: '/Downloads/Resources/User_note_COVID.pdf',
      target: '_blank',
      category: ['other'],
    },
    {
      title: 'Release of AHRQ QI SAS QI and WinQI v2020 Software.',
      id: 'software-20200731',
      date: 'July 31, 2020',
      desc: "The software allows organizations to apply the AHRQ Quality Indicators (QIs) to their own data to assist quality improvement efforts in acute hospital settings.<br/><a href='/Downloads/Software/WinQI/V2020/AHRQ_WinQI_v2020_Software_Rel_Notes.pdf'>Release Note (PDF File, 237 KB)</a></p>",
      url: '/Software',
      category: ['software'],
    },
  ],
};
