import React from 'react';
import { Container } from 'react-bootstrap';
import { resourcesData } from '../../data/resources';
import styles from './resources.module.scss';
import NormalTopBanner from '../Common/TopBanner/NormalTopBanner';
import SearchableAccordion from '../SearchableAccordion/SearchableAccordion';

const Publications = () => {
  const publications = resourcesData.publications;
  publications.sort((a, b) => a.title.localeCompare(b.title));

  const maxYear = publications
    .map((publication) => publication.year)
    .reduce((a, b) => Math.max(a, b));
  const minYear = publications
    .map((publication) => publication.year)
    .reduce((a, b) => Math.min(a, b));

  const accordionData = {
    'default-publications': 'pqi',
    publications: {
      pqi: {
        topic: 'PQI - Prevention Quality Indicators in Inpatient Settings',
        children: [],
        childrenCount: 0,
      },
      iqi: {
        topic: 'IQI - Inpatient Quality Indicators',
        children: [],
        childrenCount: 0,
      },
      psi: {
        topic: 'PSI - Patient Safety Indicators',
        children: [],
        childrenCount: 0,
      },
      pdi: {
        topic: 'PDI - Pediatric Quality Indicators',
        children: [],
        childrenCount: 0,
      },
      pqe: {
        topic: 'PQE - Prevention Quality Indicators in Emergency Department Settings',
        children: [],
        childrenCount: 0,
      },
      // mhi: {
      //   topic: 'MHI - Maternal Health Indicators',
      //   children: [],
      //   childrenCount: 0,
      // },
    },
  };

  for (const key of Object.keys(accordionData.publications)) {
    const items = publications.filter((publication) =>
      publication.indicator.toLowerCase().includes(key)
    );

    if (items.length === 0) break;

    const maxYear = items.map((publication) => publication.year).reduce((a, b) => Math.max(a, b));
    const minYear = items.map((publication) => publication.year).reduce((a, b) => Math.min(a, b));
    let childrenCount = 0;

    for (let index = maxYear; index >= minYear; index--) {
      let desc = '';
      const yearItems = items.filter((publication) => publication.year === index);

      if (yearItems.length > 0) {
        for (const publication of yearItems) {
          desc += `<div>`;
          desc += `<div class="title mb-6">${publication.title}</div>`;

          desc += `<div class="desc indicators mb-6">`;
          desc += `<div class="pills">`;
          desc += `<span class="pill-label">Indicators:</span>`;
          for (const indicator of publication.indicators) {
            desc += `<span class="pill-small">${indicator.title}</span>`;
          }
          desc += `</div>`; // .pills
          desc += `</div>`; // .desc
          desc += `</div>`;

          childrenCount++;
        }

        accordionData.publications[key].children.push({
          topic: index,
          tag: `publications-${key}-${index}`,
          desc: desc,
        });
      }

      accordionData.publications[key].childrenCount = childrenCount;
    }
  }

  return (
    <div className={styles.Publications}>
      <NormalTopBanner>
        <h1>Publications</h1>
        <p>
          The list below presents selected articles from {minYear} through {maxYear} that focus on
          the use of AHRQ QIs for quality improvement purposes.
        </p>
      </NormalTopBanner>
      <SearchableAccordion
        data={accordionData}
        section={'publications'}
        categoriesLabel='Modules'
        expandFirstItem={true}
      />

      <Container>
        <div className={styles.PublicationPDFs}>
          <p>
            For a complete list of articles by year, please go the{' '}
            <a
              href='/Downloads/Resources/Publications/FullListPublications-508.pdf'
              target='_blank'
            >
              AHRQ QI Publications Brochure (PDF File, 257 KB)
            </a>
            .
          </p>
          <p>
            For publications prior to 2021 see the{' '}
            <a href='/archive/resources?category=publications'>Archive Resources page</a>.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Publications;
