import React from 'react';
import { Box, styled, Typography } from '@mui/material';

export const HeroContent = ({ title, description, subDescription, to, promote, tag }) => {
  return (
    <CardContent className='HomeCardContent'>
      <div className='promote'>{promote ? 'Beta' : ''}</div>
      <Typography variant='h3' className='title'>
        <a href={to}>{title}</a>
        {promote === true && <span className='new'> New!</span>}
      </Typography>
      <div className='definition'>{description}</div>
      {subDescription && <div className='sub-definition'>{subDescription}</div>}
      {tag && (
        <div className='HomeHeroIndicatorsTag'>
          {tag.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      )}
    </CardContent>
  );
};

const CardContent = styled(Box)(({ theme }) => ({
  marginTop: -12,
  '.title': {
    fontSize: '2rem',
    fontWeight: 900,

    '&:after': {
      content: `""`,
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,

      display: 'block',
      backgroundColor: theme.palette.grey[300],
      height: 2,
    },
  },
  '.definition': {
    fontSize: '24px',
    lineHeight: 1.2,
    marginTop: theme.spacing(1),
    zIndex: 1,
    display: 'flex',
    flexGrow: 1,
  },
  '.promote': {
    flexShrink: 0,
    color: theme.palette.secondary.light,
    fontSize: '.875rem',
    fontWeight: 'bold',
    lineHeight: 1,
    height: 12,
    textTransform: 'uppercase',
  },
  '.new': {
    color: theme.palette.error.main,
    fontSize: '.875rem',
    fontWeight: 'bold',
    lineHeight: 1,
    height: 12,
    textTransform: 'uppercase',
    float: 'right',
  },
  '.HomeCardContent': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));
