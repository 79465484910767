import { styled } from '@mui/material';

export const BlockQuote = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <div className='bookmark-icon' />
      {children}
    </Container>
  );
};

const Container = styled('blockquote')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: 'url(/images/bg-blockquote-purple.jpg)',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '1em',
  color: 'white',
  textAlign: 'center',
  alignItems: 'center',
  padding: '3em 5em',

  [theme.breakpoints.up('md')]: {
    padding: '5em 8em',
  },

  '.bookmark-icon': {
    position: 'absolute',
    top: 0,
    left: 32,
    width: 42,
    height: 54,
    backgroundImage: 'url(/images/icon-bookmark.svg)',
    // backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}));
